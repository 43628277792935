import "lite-youtube-embed";
import "lite-youtube-embed/src/lite-yt-embed.css";

document.onreadystatechange = () => {
  if (document.readyState === "complete") {
    setTimeout(() => {
      document.body.classList.add("loaded");
    }, 500);
    document.querySelector(".lty-playbtn").outerHTML = `
    <div class="lty-playbtn" title="Play Video">
      <span class="lyt-visually-hidden">Play Trailer</span>
    </div>`;

    enableGallery();
    enableReviews();

    setTimeout(showDotMatrix, 10000);
    document
      .querySelector(".csvrs-logo")
      .addEventListener("click", showDotMatrix);
  }
};

function showDotMatrix() {
  document.body.classList.add("show-dot-matrix");
  setTimeout(() => {
    document.body.classList.remove("show-dot-matrix");
  }, 10000);
}

const images = {
  screenshot1: new URL("./assets/screenshot1.png", import.meta.url),
  screenshot2: new URL("./assets/screenshot2.png", import.meta.url),
  screenshot3: new URL("./assets/screenshot3.png", import.meta.url),
  screenshot4: new URL("./assets/screenshot4.png", import.meta.url),
};

function enableGallery() {
  const featured = document.querySelector(".featured-media");
  const thumbnails = document.querySelectorAll(
    ".thumbnails > div, .thumbnails > img"
  );
  for (const el of thumbnails) {
    el.addEventListener("click", () => {
      if (el.dataset.img) {
        featured.innerHTML = `<img class="featured-image" src="${
          images[el.dataset.img]
        }">`;
      }
      if (el.dataset.video) {
        featured.innerHTML = `<iframe src="https://www.youtube.com/embed/${el.dataset.video}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
      }
    });
  }
}

function enableReviews() {
  let reviews = Array.from(document.querySelectorAll(".reviews li"));
  reviews = shuffle(reviews);

  const positions = window.innerWidth > 744 ? 4 : 1;

  let reviewIndex = 0;
  let positionIndex = 0;

  function swapAReview() {
    const prev = reviews.find(
      (r) => parseInt(r.dataset.position) === positionIndex + 1
    );
    if (prev && prev.style.opacity !== 0) {
      prev.style.opacity = 0;
      setTimeout(() => {
        delete prev.dataset.position;
      }, 2000);
      setTimeout(() => {
        prev.style.opacity = null;
      }, 2200);
    }
    reviews[reviewIndex].dataset.position = positionIndex + 1;
    reviews[reviewIndex].style.opacity = null;

    reviewIndex += 1;
    if (reviewIndex >= reviews.length) {
      reviewIndex = 0;
    }
    positionIndex += 1;
    if (positionIndex > positions - 1) {
      positionIndex = 0;
    }
  }
  setTimeout(() => {
    for (let index = 0; index < positions; index++) {
      const delay = (index + 1) * 500;
      setTimeout(swapAReview, delay);
    }

    setTimeout(() => {
      setInterval(() => {
        swapAReview();
      }, 4000 / positions);
    }, 1500);
  }, 1800);
}

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
